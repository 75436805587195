@import 'src/assets/scss/color-palette';

.wrap {
  display: flex;
  align-items: center;

  div:nth-child(even) {
    color: #363a45;
    font-weight: normal;
  }

  :global {
    & div:nth-child(2n + 1) {
        color: $regent-gray;
    }
  }
}
